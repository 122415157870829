<template>

  <div :class="'tree'" v-if="props.tree.length">

    <h2 class="divider-sec">
      {{ title }}       
    </h2>

    <div class="tree-cont">


      <ClientOnly>
        <VueBlocksTree :data="treeData" :horizontal="true" :collapsable="false" :props="{label: 'label', expand: true, children: 'children',  key:'path_strain_id'}">
          <template #node="{data,context}">
            <template v-if="data?.is_hidden || data?.is_removed">              
              <div class="nd">
                <img v-if="data?.cover_s" :src="data?.cover_s" class="cover" />
                <span class="label">{{ data?.name }}</span>
              </div>
            </template>
            <template v-else>            
              <NuxtLink class="nd" :to="'/strains2' + (data?.section ? '/' + data?.section : '') ">
                <img v-if="data?.cover_s" :src="data?.cover_s" class="cover" />
                <span class="label">{{ data?.name }}</span>
              </NuxtLink>
            </template>
          </template>
        </VueBlocksTree>
      </ClientOnly>
    </div>
        
  </div>
</template>

<script setup>


import VueBlocksTree from 'vue3-blocks-tree';
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css';

const props = defineProps({
  title: {
    type: String,
    default: 'Family Tree'
  },
  tree: {
    type: Array,
    required: true,
  },
});
 

const treeData = computed(() => {
  const map = {};
  const roots = [];

  props.tree.forEach((node) => {
    map[node.path_strain_id] = { ...node, children: [] };
  });

  props.tree.forEach((node) => {
    node['expand'] = true;
    map[node.path_strain_id].expand = true;
    if (node.child_id === null) {
      roots.push(map[node.path_strain_id]);
    } else {
      const parent = props.tree.find((item) => item.path_strain_id === node.path_child_id && item.level < 2);
      if (parent) {
        map[parent.path_strain_id].children.push(map[node.path_strain_id]);
        // uniq array 
        
      }
    }
  });


 

  return roots[0];
});
</script>

<style scoped>


 
.tree {
  
}

.tree-cont {
  overflow-x: auto;
  width: 100%;
  display: flex;
}
.tree-cont::-webkit-scrollbar {
  display: none;
}
.tree-cont:deep(.org-tree-container) {
  flex-shrink: 0;
}

.tree .list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
}
 
.tree .list :deep(.seed){
  flex-shrink: 0;
}


@container pb (max-width: 550px) {
  .tree .list :deep(.seed){
    width: calc(50% - 40px);
  }
}


/* tree */


.nd {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nd .cover {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: contain;
  background-color: white;
  padding: 5px;
}

.nd .label {
  font-weight: bold;
  color: var(--color-text);
  margin-right: 10px;
}

.tree:deep(.org-tree-container){
  padding: 0;
}

.tree:deep(.org-tree-node-label .org-tree-node-label-inner){
  border-radius: 100px;
  box-shadow: none;
  background-color: var(--un-background-color-gray);

}


 
</style>